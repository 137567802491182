import * as fediday from 'mastodon/addons/fediday';
import * as snow from 'mastodon/addons/snow';
import Rails from '@rails/ujs';

export function start() {
  require.context('../images/', true, /\.(jpg|png|svg)$/);

  try {
    Rails.start();
  } catch {
    // If called twice
  }
}
